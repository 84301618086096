@import "node_modules/bootstrap/scss/functions";
@import "node_modules/bootstrap/scss/variables";
@import "node_modules/bootstrap/scss/mixins/_breakpoints";
@import '../../commons/styles/mixins.scss';

.home {

  // align-items: flex-end;
  align-items: center;
  background-attachment: fixed;
  background-position: right;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-flow: column;
  justify-content: center;
  height: 100vh;
  overflow: hidden;
  width: 100vw;
  opacity: 0;

  @include animation(0.5s, 1.3s, fadeIn);

  h1 {
    text-align: center;
    font-size: 4rem;
    opacity: 0;

    @include animation(0.5s, 3s, fadeIn);

    @include media-breakpoint-up(sm) {
      font-size: 5rem;
    }
    @include media-breakpoint-up(md) {
      font-size: 6rem;
    }
  }

  a {
    color: black;
    font-size: 2rem;
    opacity: 0;
    padding: 3rem;
    text-decoration: none;
    text-transform: uppercase;
    transition: opacity 1.7s ease-out 2s;
    @include animation(1s, 3s, fadeIn);

    &:hover{
      color: black;
      opacity: 0.7;
      text-decoration: none;
      transition: opacity 1.7s;
    }
  }
}

@keyframes fadeIn {
  from { opacity: 0; }
  to   { opacity: 1; }
}

/* Firefox < 16 */
@-moz-keyframes fadeIn {
  from { opacity: 0; }
  to   { opacity: 1; }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadeIn {
  from { opacity: 0; }
  to   { opacity: 1; }
}

/* Internet Explorer */
@-ms-keyframes fadeIn {
  from { opacity: 0; }
  to   { opacity: 1; }
}

@keyframes fadeIn {
  from { opacity: 0; }
  to   { opacity: 1; }
}
