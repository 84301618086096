@import "node_modules/bootstrap/scss/functions";
@import "node_modules/bootstrap/scss/variables";
@import "node_modules/bootstrap/scss/mixins/_breakpoints";

.main-header {
  display: flex;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 999;

  .header-logo {
    padding-left: .5rem;

    h1 {
      font-size: 1.5rem;
      a {
        color: black;
        text-decoration: none;
      }

      @include media-breakpoint-down(xs) {
        text-align: center;
      }
    }

    // A LA MEJOR QUITAMOS ESTA PARTE
    @include media-breakpoint-down(sm) {
      // background: rgba(255,255,255,1);
      // background: -moz-linear-gradient(top, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 30%, rgba(255,255,255,0) 100%);
      // background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(255,255,255,1)), color-stop(30%, rgba(255,255,255,1)), color-stop(100%, rgba(255,255,255,0)));
      // background: -webkit-linear-gradient(top, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 30%, rgba(255,255,255,0) 100%);
      // background: -o-linear-gradient(top, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 30%, rgba(255,255,255,0) 100%);
      // background: -ms-linear-gradient(top, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 30%, rgba(255,255,255,0) 100%);
      // background: linear-gradient(to bottom, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 30%, rgba(255,255,255,0) 100%);
      // filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#ffffff', GradientType=0 );
      width: 100%;
    }
  }

  .menu {
    list-style: none;
    margin-left: auto;
    padding: 0;
    &.side {
      background: white;
      height: 100vh;
      position: absolute;
      right: 0;
      transition: right 0.5s;

      &.hidden {
        right: -97px;
        transition: right 0.5s;
      }

      li {
        display: block;
      }

      @include media-breakpoint-down(sm) {
        background: black;
        a {
          color: white;
        }
      }

      @include media-breakpoint-up(sm) {
        background: transparent;
        height: auto;
        text-transform: capitalize;
        transform: rotate(-90deg);
        transform-origin: 85% 65%;
        position: static;
        width: auto;

        .burger-menu{
          display: none;
        }

        li {
          display: inline-block;
        }
      }
    }
    &.close {
      font-family: 'Monserrat';
      font-weight: 500;
    }

    li {
      padding: 1rem;

      a {
        color: black;
        text-decoration: none;
        transition: opacity 0.5s;

        &:hover{
          opacity: 0.7;
          // FUNCIONARA?
          text-shadow: 0 0 1px black;
          transition: opacity 0.5s;
        }
      }
    }
  }

}

.hamburguer-bt {
  background: white;
  border: none;
  cursor: pointer;
  height: 40px;
  outline: none !important;
  position: absolute;
  left: -40px;
  width: 40px;
  transition: all .2s ease-out;
  @include media-breakpoint-down(sm) {
    background: black;
  }
}

.hamburguer-bt .hamburguer-bt__stripe {
  width: 100%;
  height: 4px;
  background: black;
  margin: 4px auto;
  transition: all 0.3s;
  backface-visibility: hidden;
  @include media-breakpoint-down(sm) {
    background: white;
  }
}

.hamburguer-bt.on .hamburguer-bt__stripe__top {
  transform: rotate(45deg) translate(7px, 5px);
}

.hamburguer-bt.on .hamburguer-bt__stripe__middle {
  opacity: 0;
}

.hamburguer-bt.on .hamburguer-bt__stripe__bottom {
  transform: rotate(-45deg) translate(7px, -5px);
}

