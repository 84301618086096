@import '../../commons/styles/mixins.scss';

.loader-container {
  overflow: hidden;
  position: fixed;
  width: 100%;

  .loader {
    background: black;
    bottom: 0;
    display: none;
    height: 5px;
    left: 0;
    position: fixed;
    width: 100%;
    z-index: 99;

    &.loading {
      @include animation(0s, 2s, loader, both);
      animation-delay: 1s;
      animation-iteration-count: infinite;
      animation-direction: alternate;
      display: block;
    }
  }
}

@keyframes loader {
  0%    { left: -100%; }
  15%    { left: -100%; }
  48%   { left: 0; }
  51%   { left: 0; }
  85%  { left: 100%; }
  100%  { left: 100%; }
}

/* Firefox < 16 */
@-moz-keyframes loader {
  0%    { left: -100%; }
  15%    { left: -100%; }
  48%   { left: 0; }
  51%   { left: 0; }
  85%  { left: 100%; }
  100%  { left: 100%; }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes loader {
  0%    { left: -100%; }
  15%    { left: -100%; }
  48%   { left: 0; }
  51%   { left: 0; }
  85%  { left: 100%; }
  100%  { left: 100%; }
}

/* Internet Explorer */
@-ms-keyframes loader {
  0%    { left: -100%; }
  15%    { left: -100%; }
  48%   { left: 0; }
  51%   { left: 0; }
  85%  { left: 100%; }
  100%  { left: 100%; }
}
