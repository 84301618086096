@import "node_modules/bootstrap/scss/functions";
@import "node_modules/bootstrap/scss/variables";
@import "node_modules/bootstrap/scss/mixins/_breakpoints";
@import '../../commons/styles/helper-classes.scss';

$hoverTime: 0.2s;

.work {
  @extend .content-element;

  .custom-class {
    display: block;
  }

  .row {
    justify-content: center;
  }

  .row .col-6 {

    a > div {
      margin-bottom: 50px;
      @include media-breakpoint-up(sm) {
        margin-bottom: 300px;
      }
    }
    &.left-col {
      margin-right: -20px;
      @include media-breakpoint-up(sm) {
        margin-right: -20px;
      }
    }
    &.right-col {
      margin-left: -20px;
      @include media-breakpoint-up(sm) {
        margin-left: -20px;
      }
    }

    a {

      div {
        transition: margin-bottom $hoverTime;
      }
      img {
        transition: width $hoverTime, filter $hoverTime;
      }

      &:hover {

        div {
          margin-bottom: 42px;
          transition: margin-bottom $hoverTime;
          @include media-breakpoint-up(sm) {
            margin-bottom: 294px;
          }
        }
        img {
          width: calc(100% + 10px)!important;
          z-index: 99;
          transition: width $hoverTime, filter $hoverTime;
        }
      }
    }
  }
}
