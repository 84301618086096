@import '../../commons/styles/helper-classes.scss';

.about {
  @extend .content-element;
}

.award {
  font-weight: bold;

}

.category-award {
  display: block;
}
