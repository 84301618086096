@import '../../commons/styles/mixins.scss';

$filter: blur(8px);

iframe {
  border: none;
  height: 100vh;
  width: 90vw;
}

.loading {
  @include animation(0s, 1s, fadeIn);
  opacity: 0;
  filter: $filter;
  -webkit-filter: $filter;
  transition: filter 1.3s;

  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.loaded {

  position: relative;
  -webkit-filter: $filter;
	filter: blur(0);

  animation-fill-mode: forwards;
  animation-duration: 0.7s;
  animation-delay: 0.1s;
  transition: filter 1.3s;

}

@keyframes fadeIn {
  from { opacity: 0; }
  to   { opacity: 1; }
}

/* Firefox < 16 */
@-moz-keyframes fadeIn {
  from {   opacity: 0; }
  to   { opacity: 1; }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadeIn {
  from {   opacity: 0; }
  to   { opacity: 1; }
}

/* Internet Explorer */
@-ms-keyframes fadeIn {
  from {   opacity: 0; }
  to   { opacity: 1; }
}


@keyframes blurOut {
  from {   filter: $filter; }
  to   { filter: blur(0); }
}

/* Firefox < 16 */
@-moz-keyframes blurOut {
  from {   filter: $filter; }
  to   { filter: blur(0); }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes blurOut {
  from {   filter: $filter; }
  to   { filter: blur(0); }
}

/* Internet Explorer */
@-ms-keyframes blurOut {
  from {   filter: $filter; }
  to   { filter: blur(0); }
}
