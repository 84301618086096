@import-normalize;

@font-face {
  font-family: 'Monserrat';
  src: url(https://fonts.gstatic.com/s/montserrat/v14/JTURjIg1_i6t8kCHKm45_ZpC3gnD_g.woff2) format('woff2');;
  font-weight: 500;
  font-style: normal;

}
@font-face {
  font-family: 'Monserrat';
  src: url(https://fonts.gstatic.com/s/montserrat/v14/JTURjIg1_i6t8kCHKm45_dJE3gnD_g.woff2) format('woff2');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'NeueMachina';
  src: url('./resources/fonts/NeueMachina/NeueMachina-Light.woff2') format('woff2');
  font-weight: lighter;
  font-style: normal;

}

@font-face {
  font-family: 'NeueMachina';
  src: url('./resources/fonts/NeueMachina/NeueMachina-Regular.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'NeueMachina';
  src: url('./resources/fonts/NeueMachina/NeueMachina-Ultrabold.woff2') format('woff2');
  font-weight: bold;
  font-style: normal;
}

body {
  margin: 0;
  font-family: 'Monserrat', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1 {
  font-family: 'Monserrat';
  font-size: 2rem;
  font-weight: 700;
  margin: 0;
  letter-spacing: 2px;
}

h2 {
  font-family: 'Monserrat';
  font-weight: 700;
}

a, label {
  font-family: 'Monserrat';
}

p {
  font-family: 'NeueMachina';
  font-size: 0.9rem;
  text-align: justify;
}

.content-element {
  padding-top: 50px;
}
