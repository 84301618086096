@import "node_modules/bootstrap/scss/functions";
@import "node_modules/bootstrap/scss/variables";
@import "node_modules/bootstrap/scss/mixins/_breakpoints";
@import '../../commons/styles/helper-classes.scss';

.contact {
  @extend .content-element;

  .contact-info {
    div{
      margin-bottom: 45px;
      * {
        display: block;
        word-break: break-all;
      }

      label {
        font-weight: bold;
      }

      a {
        color: black;
      }

      @include media-breakpoint-up(sm) {
        margin-bottom: 90px;
      }
    }
    @include media-breakpoint-down(sm) {
      padding-top: 45px;
    }
  }
}
