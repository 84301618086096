
@mixin animation($delay, $duration, $animation, $fillMode: forwards) {
  -webkit-animation-delay: $delay;
  -webkit-animation-duration: $duration;
  -webkit-animation-name: $animation;
  -webkit-animation-fill-mode: $fillMode; /* this prevents the animation from restarting! */

  -moz-animation-delay: $delay;
  -moz-animation-duration: $duration;
  -moz-animation-name: $animation;
  -moz-animation-fill-mode: $fillMode; /* this prevents the animation from restarting! */

  -o-animation-delay: $delay;
  -o-animation-duration: $duration;
  -o-animation-name: $animation;
  -o-animation-fill-mode: $fillMode; /* this prevents the animation from restarting! */

  animation-delay: $delay;
  animation-duration: $duration;
  animation-name: $animation;
  animation-fill-mode: $fillMode; /* this prevents the animation from restarting! */
}
