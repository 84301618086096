@import '../../commons/styles/helper-classes.scss';

.project {
  height: 100vh;
  padding: 0 1rem;

  h2 {
    font-family: 'NeueMachina';
    font-size: 1rem;
    font-weight: normal;
    text-align: center;
  }

  .awssld__content > div {
    height: 100%;
  }

  .awssld__controls button {
    outline: none;
    color: #000!important;

    &:hover:before{
      opacity: 0.5;
      transition: opacity .3s ease-in-out;
    }

    &:before{
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-bottom: 10px solid transparent;
      border-top: 10px solid transparent;
      content: "";
      opacity: 0.1;
      transition: opacity .3s ease-in-out;
    }

    &.awssld__next:before {
      border-left: 10px solid black;
    }

    &.awssld__prev:before {
      border-right: 10px solid black;
    }
  }
}
